:root {
  --clr-dark: #1e2228;
  --clr-light: #838c9a;
  --clr-blue: #25c;
  --clr-blue-light: #255cdb;
  --clr-grey: #f7f8f9;
  --clr-white: #ffffff;
  --clr-blue-txt: #2b5ccf;
  --font-primary: "Inter", sans-serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 9.5px;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 9px;
  }
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}
body {
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-dark);
}

section {
  padding: 10rem 2%;
  position: relative;
}

p {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}

p.huge {
  font-size: 2rem;
}

image {
  max-width: 100%;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

h2.section-title {
  font-size: 4.6rem;
}

/*  -------- Buttons styles -------- */
.btn {
  text-decoration: none;
  padding: 1.4rem 2.7rem;
  font-size: 1.9rem;
  display: inline-block;
  font-size: 2rem;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.btn--block {
  background-color: var(--clr-blue);
  color: var(--clr-white);
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  transform: scale(0.9);
}

.btn--block:hover {
  background-color: var(--clr-blue-light);
  color: var(--clr-white);
}

.page-title-container {
  padding: 6rem 5%;
}

.page-title {
  font-size: 6.4rem;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 1.2;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 350px) {
  .page-title {
    font-size: 5rem;
  }
}
.page-title-img {
  width: 80%;
}

.page-title p {
  font-weight: 300;
}

.logo {
  width: 16rem;
  padding: 1rem 0;
}

/* ------------------ GRIDS ------------ */
.hero .container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 3rem;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
}

@media only screen and (max-width: 1000px) {
  .hero .container {
    grid-template-columns: 2fr 0.5fr;
  }
}
@media only screen and (max-width: 768px) {
  .hero .container {
    grid-template-columns: 1fr;
  }
}
.about-section__text {
  display: grid;
  grid-template-columns: 1.4fr 3fr;
}

@media only screen and (max-width: 768px) {
  .about-section__text {
    grid-template-columns: 1fr;
  }
}
.services-section__content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  grid-template-rows: auto;
}

@media only screen and (max-width: 990px) {
  .services-section__content {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .services-section__content {
    grid-template-columns: 1fr;
  }
}
.testimonials-section__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

@media only screen and (max-width: 768px) {
  .testimonials-section__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .testimonials-section__content {
    grid-template-columns: 1fr;
  }
}
.project-card {
  padding: 10rem 2%;
}

.project-card:nth-child(even) {
  background-color: var(--clr-grey);
}

.project-card:last-child {
  border-bottom: 1px solid var(--bs-gray-300);
}

.project-card .btns a img {
  width: 17.5rem;
  height: 5.5rem;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.project-card .btns a img:hover {
  transform: scale(0.96);
}

.project-icon {
  width: 8rem;
}

.project-name {
  font-size: 3.2rem;
  margin: 2.8rem 0 2.3rem 0;
  color: var(--clr-blue-txt);
  line-height: 1.4;
}

.project-category {
  margin-bottom: 1.6rem;
}

.project-desc {
  margin-bottom: 5rem;
  font-weight: 300;
}

.project-mockup {
  width: 30rem;
}

.project-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 3%;
}

.location-box h3 {
  font-size: 2rem;
}

.location-box li {
  font-size: 1.8rem;
}

.location-box h3 {
  font-size: 2rem;
}

.location-box li {
  font-size: 1.8rem;
}

.service-title {
  font-size: 3.2rem;
  color: var(--clr-blue-txt);
}

.service-img {
  width: 18rem;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .service-img {
    width: 10rem;
  }
}
.service-page-card {
  padding: 6rem 3%;
}

.service-page-card:nth-child(odd) {
  background-color: var(--clr-grey);
}

.navbar {
  font-size: 1.8rem;
  font-family: inherit;
}

.nav-item {
  align-self: center;
}

@media only screen and (max-width: 991px) {
  .nav-item {
    align-self: stretch;
  }
}
.nav-cta {
  text-decoration: none;
  margin: 1rem;
  padding: 0.5rem 2.3rem;
  font-size: 16px;
  background-color: var(--clr-blue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.2s;
}

.nav-cta:hover {
  background-color: var(--clr-blue-light);
  color: var(--clr-white);
  transform: scale(0.9);
}

/* --------- Hero -------- */
.hero {
  padding-top: 18rem;
  position: relative;
  min-height: 75vh;
  overflow: hidden;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  filter: brightness(0.7) blur(2px);
  transform: scale(1.1);
}

.hero-bg video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 650px) {
  .hero {
    background-position: center left;
  }
}
.hero__text-col {
  color: var(--clr-white);
}

.hero__text-col .btn {
  margin-top: 4rem;
}

@media only screen and (max-width: 768px) {
  .hero span {
    display: none;
  }
}
.hero__layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.hero__layer svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 69px;
  transform: rotateY(180deg);
}

.hero__layer .shape-fill {
  fill: #FFFFFF;
}

/* --------- About_Section ------- */
@media only screen and (max-width: 768px) {
  .about-section__text p {
    margin-top: 6rem;
  }
}
.about-section__b-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.about-section__b-layer svg {
  position: relative;
  display: block;
  width: calc(298% + 1.3px);
  height: 66px;
}

.about-section__b-layer .shape-fill {
  fill: var(--clr-grey);
}

/* --------- Services_Section ------- */
.services-section {
  background-color: var(--clr-grey);
  background-image: url(../public/images/circle-scatter-haikei.svg);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.services-section__content {
  margin-top: 8rem;
  padding: 0 2rem;
}

/* --------------- TestimonialsSection ----------- */
.testimonials-section__content {
  padding: 0 2rem;
  margin-top: 8rem;
}

.contact-section {
  background-image: linear-gradient(120deg, var(--clr-blue-txt) 0%, var(--clr-blue-light) 100%);
  font-size: inherit;
}

.contact-section .contact-top-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.contact-section .contact-top-divider svg {
  position: relative;
  display: block;
  width: calc(108% + 1.3px);
  height: 46px;
}

.contact-section .contact-top-divider .shape-fill {
  fill: var(--clr-grey);
}

.contact-section .custom-shape-divider-bottom-1643260595 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.contact-section .custom-shape-divider-bottom-1643260595 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 52px;
}

.contact-section .custom-shape-divider-bottom-1643260595 .shape-fill {
  fill: #FFFFFF;
}

.contact-section .row {
  font-size: 1.8rem;
}

.contact-section h3 {
  font-size: 2rem;
}

.contact-section .form-control {
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 55px;
  font-size: inherit;
}

.contact-section .form-control:active, .contact-section .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #000;
}

.contact-section .col-form-label {
  color: #000;
  font-weight: 300;
}

.contact-section .box {
  padding: 3.5rem 6.5rem;
  background: white;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
}

.contact-section .box h3 {
  margin-bottom: 30px;
  text-align: center;
}

.footer {
  background-color: var(--clr-white);
  padding: 4rem 2%;
}

.footer .site-logo {
  font-size: 3rem;
  color: var(--clr-blue);
}

.footer .social-icons li {
  display: inline-block;
}

.footer .social-icons li:not(:last-child) {
  margin-right: 1.8rem;
}

.footer .social-icons li a {
  display: inline-block;
  position: relative;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.footer .social-icons li a:hover {
  opacity: 0.9;
}

.footer .social-icons li a.dr {
  background: #ff2e6e;
}

.footer .social-icons li a.be {
  background: #394cff;
}

.footer .social-icons li a.tw {
  background: #00a0fb;
}

.footer .social-icons li a.in {
  background: #c31574;
}

.footer .social-icons li a.fb {
  background: #3b579b;
}

.footer .social-icons li a.wa {
  background: #25d366;
}

.footer .social-icons li a.gm {
  background: #ea4335;
}

.footer .social-icons li a.yt {
  background: #fa2614;
}

.footer .social-icons li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .social-icons li a span i {
  font-size: 2.6rem;
}

.footer .nav-links li {
  display: inline-block;
}

.footer .nav-links li:not(:last-child) {
  margin-right: 1rem;
}

.footer .nav-links li a {
  font-size: 1.8rem;
  color: #777;
  padding: 1rem;
  transition: color 0.2s ease-in-out;
}

.footer .nav-links li a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.footer .copyright {
  border-top: 1px solid #efefef;
  padding-top: 5rem;
  text-align: center;
  color: #777;
}

/* ---- QuoteCard ----- */
.quote-card {
  background-image: url(../public/images/quote.svg);
  background-repeat: no-repeat;
  background-position: 95% 95%;
  background-size: 30%;
  border: 1px solid #dadada;
  padding: 3rem;
}

.quote-card__author {
  margin-top: 2.2rem;
}

.quote-card__author-name {
  font-size: 2rem;
  color: var(--clr-blue-txt);
}

.quote-card__author-position {
  font-size: 1.4rem;
  color: var(--clr-light);
}

/* --------- ServiceCard ----------- */
.service-card {
  display: inline-block;
}

.service-card__link-wrapper {
  border-radius: 2px;
  display: inline-block;
  padding: 2rem 1rem 2rem 2rem;
  text-decoration: none;
  color: var(--clr-blue-txt);
  transition: all 0.2s ease-out;
  border: 1px solid #dadada;
}

.service-card__link-wrapper:hover {
  background-color: rgba(34, 85, 204, 0.863);
  transform: scale(1.05);
  color: var(--clr-white);
}

.service-card__link-wrapper:hover .service-card__image {
  transform: translateX(9px) translateY(5px);
}

.service-card__title {
  font-size: 1.8rem;
  margin-top: 3.5rem;
}

.service-card__image {
  width: 6rem;
  transition: all 0.2s ease-in;
}

.page-hero {
  font-size: 1.8rem;
}

/*# sourceMappingURL=App.css.map */
/*# sourceMappingURL=App.css.map */

/*# sourceMappingURL=App.css.map */
